<template>
    <div class="google_adgroup">
        <el-form :model="ruleForm" :rules="rules" ref="ruleForm">
            <div class="google_tit">
                <el-form-item label="广告组名称" prop="name">
                    <el-input v-model="ruleForm.name" type="textarea" maxlength="999999" show-word-limit placeholder="请输入内容" v-limitWords="80" @input="$maxLength('name',80)"  @change="f_input"></el-input>
                </el-form-item>
                <el-form-item label="状态" prop="status" v-if="1!=1">
                    <el-switch v-model="ruleForm.status" @change="f_input"></el-switch>
                </el-form-item>
            </div>
            <!-- 4 受众特征-->
            <div class="title_part" id="id7">受众特征</div>
            <audience v-model="ruleForm.adgroup_criterias" @input="f_inputAdgroup"></audience>
            <!-- 5 受众群体-->
            <div class="title_part tit_heigth_10" id="id8">受众群体</div>
            <div style="margin: 10px  20px;">
                <interest v-model="ruleForm.adgroup_criterias" @input="f_inputAdGroupBrowse"></interest>
            </div>
        </el-form>

    </div>
</template>

<script>
import { cloneDeep } from 'lodash'
import audience from '@/views/google/createGgAd/components/audience/index.vue'
import interest from '@/views/google/createGgAd/components/interest/index.vue'
export default {
    components:{audience,interest},
    props:{
        value:{
            type:Object,
            default:()=>{}
        },
    },
    data(){
        return {
          ruleForm:{
              adgroup_criterias:[],//受众特征 受众浏览
          },
          rules:{
            name: [
                { required: true, message: '请输入广告组名称', trigger: ['blur','change'] },
            ],
          },
          
        }
    },
    computed:{
        thirdData(){
            return this.value
        },
    },
    watch:{
        thirdData:{
            handler(newV){
                this.f_initAdsets(newV)
            },
            immediate:true,
            deep:true
        }
    },
    created(){},
    mounted(){},
    beforeDestroy(){},
    methods:{
        // 初始化数据
        f_initAdsets(newV){
            console.log(newV,"这是广告组-----")
            this.ruleForm = cloneDeep(newV)
        },
        //   受众特征
        f_inputAdgroup(data){
            // 合并受众浏览和受众特征的数据
            this.ruleForm.adgroup_criterias = [...data]
            this.f_input()
        },
        // 受众浏览
        f_inputAdGroupBrowse(data){
            // 合并受众浏览和受众特征的数据
            this.ruleForm.adgroup_criterias = [...data]
            this.f_input()
        },
        // 改变表单的值
        f_input(){
            this.$emit('input',cloneDeep(this.ruleForm))
        }
    },
}
</script>

<style lang="scss" scoped>
.google_adgroup{
    padding: 10px 0;
    max-height: 650px;
    overflow: hidden;
    overflow-y: auto;
    .title_part{
        margin-left: 25px;
    }
    .google_tit{
        padding: 0 20px;
    }
}
</style>