<template>
    <div class="google_ads create_gg_ad">
        <div class="rt_ad_content">
            <el-form :model="ruleForm" :rules="rules" ref="ruleFormName">
                <div class="google_tit">
                    <!-- <el-form-item label="广告名称" prop="name">
                        <el-input v-model="ruleForm.name" type="textarea" maxlength="999999" show-word-limit placeholder="请输入内容" v-limitWords="80" @input="$maxLength('name',80)"  @change="f_input"></el-input>
                    </el-form-item> -->
                    <el-form-item label="推广链接" prop="landing_url">
                        <el-input v-model="ruleForm.landing_url" type="textarea" show-word-limit placeholder="请输入推广链接"   @change="f_input"></el-input>
                    </el-form-item>
                    <el-form-item label="状态" prop="status" v-if="1!=1">
                        <el-switch v-model="ruleForm.status" @change="f_input" ></el-switch>
                    </el-form-item>
                </div>
                <!-- part 2 上传创意--> 
                <div class="part_1" id="id2"><my-title title="上传创意"></my-title></div>
                <div class="upload_materials">
                    <found-ad v-if="ruleForm.ad_type.indexOf('DISCOVERY')>-1 " :accountId="accountId" :defaultCallToActions="defaultCallToActions" v-model="ruleForm.discovery_multi_asset_ad_info" @input="f_foundAd" :isPass="isPass" :isCludeArabic="isCludeArabic"></found-ad>
                    <video-ad v-if="ruleForm.ad_type.indexOf('VIDEO')>-1 " :accountId="accountId" :defaultCallToActions="defaultCallToActions" v-model="ruleForm.video_responsive_ad_info" :isPass="isPass" @input="f_videoAd" :isCludeArabic="isCludeArabic"></video-ad>
                </div>
            </el-form>
        </div>
    </div>
</template>

<script>
import { cloneDeep } from 'lodash'
import MyTitle from '@/views/google/createGgAd/components/title.vue'
import foundAd from '@/views/google/createGgAd/components/foundAd.vue'
import VideoAd from '@/views/google/createGgAd/components/VideoAd.vue'
export default {
    components:{foundAd,VideoAd,MyTitle},
    props:{
        value:{
            type:Object,
            default:()=>{}
        },
        isPass:{
            type:Boolean,
            default:false
        },
        accountId: {
            type: [String, Array],
            default: [],
        },
        campaignData:{
            type:Object,
            default:()=>({})
        }
    },
    data(){
        return {
            defaultCallToActions:{},//选择国家后默认选中的行动号召力选项
            ruleForm:{
                discovery_multi_asset_ad_info:{
                    marketing_images:[],
                    square_marketing_images:[],
                    portrait_marketing_images:[],
                    logo_images:[],
                    headlines: [{
                        text: ''
                    }],
                    descriptions: [{
                        text: ''
                    }],
                },//发现广告
                video_responsive_ad_info:{
                    headlines: [{
                        text: ''
                    }],
                    long_headlines: [{
                        text: ''
                    }],
                    descriptions: [{
                        text: ''
                    }],
                    videos:[],
                    companion_banners:[],
                    call_to_actions:[]
                },//视频广告
                ad_type:'',//写死的数值 VIDEO_RESPONSIVE_AD 视频广告   DISCOVERY_MULTI_ASSET_AD 发现广告
            },
            rules:{
                // name: [
                //     { required: true, message: '请输入广告名称', trigger: ['blur','change'] },
                // ],
                landing_url: [
                    { required: true, message: '请输入推广链接', trigger: ['blur','change'] },
                ],
            },
        }
    },
    computed:{
        thirdData(){
            return this.value
        },
        isCludeArabic(){
          return this.campaignData.language?this.campaignData.language.includes(1019):false
      }
    },
    watch:{
        thirdData:{
            handler(newV){
                this.f_initAds(newV)
            },
            immediate:true,
            deep:true
        },
        isPass:{
            handler(newV){
                this.f_submit()
            },
        },
    },
    created(){},
    mounted(){},
    beforeDestroy(){},
    methods:{
        // 校验名字
        f_submit(){
            this.$refs.ruleFormName.validate((valid) => {
                if (!valid) {
                    this.$store.commit('initData/setGoogleValidName',false)
                    this.$firstInputFocus()
                    return false;
                }
                this.$store.commit('initData/setGoogleValidName',true)

            });
        },
        // 初始化广告
        f_initAds(newV){
            this.ruleForm = cloneDeep(newV)
            console.log(newV,"这是初始化广告了---")
        },
        // 发现广告
        f_foundAd(data){
            this.ruleForm.discovery_multi_asset_ad_info = {...data}
            this.ruleForm.ad_type = 'DISCOVERY_MULTI_ASSET_AD'
            this.f_input()
            console.log(this.ruleForm,"发现广告------所有广告集合")
        },
        // 视频广告
        f_videoAd(data){
            this.ruleForm.video_responsive_ad_info = {...data}
            this.ruleForm.ad_type = 'VIDEO_RESPONSIVE_AD'
            console.log(this.ruleForm,"视频广告------所有广告集合")
            this.f_input()
        },
        f_input(){
            this.$emit('input',cloneDeep(this.ruleForm))
        },
    },
}
</script>

<style lang="scss" scoped>
@import "~@/styles/google/googleCreate.scss";
.google_ads{
    padding: 0px 0;
    max-height: 650px;
    overflow: hidden;
    overflow-y: auto;
    .rt_ad_content{
        overflow-y: hidden;
        max-height: 100%;
    }
    .google_tit{
        padding: 20px 20px 0;
    }
}
</style>